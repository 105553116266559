import { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import TextField from '../../Form/TextField';
import CustomSelect from '../../Form/CustomSelect';
import Button from '../../Form/Button';
import { useAddVendorDirectly } from '../../../hooks/queries-and-mutations/vendor';
import { InfoModal } from '../InfoModal';
import { VendorType } from '../../../types/vendor.type';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { TradeTerms } from '../../../constants';

type Props = {
  onCancel: () => void;
  onSuccess: () => void;
  vendorData: VendorType | null;
  businessId: string;
};

export default function AddDirectVendorModal({ onCancel, onSuccess, businessId }: Props) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { mutateAsync: addVendorDirectly } = useAddVendorDirectly();

  const formik = useFormik({
    initialValues: {
      vendorName: '',
      contactEmail: '',
      contactName: '',
      contactPhone: '',
      goodsSupplied: '',
      termsOfTrade: '',
      type: '',
    },
    validationSchema: Yup.object({
      vendorName: Yup.string().required('Vendor name is required').nullable(),
      contactEmail: Yup.string()
        .email('Invalid Email')
        .required('Email is required')
        .nullable(),
      contactPhone: Yup.string().required('Please select a vendor type').nullable(),
      goodsSupplied: Yup.string().required('Good supplied is required').nullable(),
      termsOfTrade: Yup.string().required('Terms of trade is required').nullable(),
      contactName: Yup.string().required('Contact name is required').nullable(),
      type: Yup.string().required('Vendor type is required').nullable(),
    }),
    onSubmit: (values) => {
      setIsSubmitting(true);
      addVendorDirectly({ businessId, payload: values })
        .then(() => {
          setIsSubmitting(false);
          onSuccess();
        })
        .catch((err) => {
          const error = err as AxiosError<Error>;
          toast.error(error?.message ?? 'Sorry!, An error has occurred');
          onCancel();
        });
    },
  });

  return (
    <InfoModal
      width="min-[1100px]:w-[45%] max-[1100px]:w-[60%] min-w-[500px] max-[650px]:min-w-[300px] max-[650px]:w-[90%]"
      className="h-fit"
      onClose={onCancel}
    >
      <div className="rounded-[20px] bg-white py-8 min-[650px]:px-8 max-[650px]:px-0 font-circular text-2xl font-normal text-[#353535]">
        <div className="flex flex-col">
          <div className="py-5 pb-3 text-left border-b">
            <p className="text-xl font-medium text-black font-inter">
              {'Add vendor directly'}
            </p>
            <p className="text-sm font-normal font-inter text-black/40">
              {'Enter the details of the vendor you want to add directly'}
            </p>
          </div>

          <div className="w-full mx-auto my-8">
            <form className="space-y-0.5" onSubmit={formik.handleSubmit}>
              <TextField
                name="vendorName"
                type="text"
                value={formik.values.vendorName}
                onChange={formik.handleChange}
                onBlur={formik.handleChange}
                label="Vendor Name"
                error={formik.touched.vendorName ? formik.errors.vendorName : ''}
                className="bg-white"
                requiredIndicator
              />

              <div className="grid flex-1 grid-cols-1  gap-x-5 gap-y-2 sm:grid-cols-2">
                <TextField
                  name="contactEmail"
                  type="email"
                  value={formik.values.contactEmail}
                  onChange={formik.handleChange}
                  onBlur={formik.handleChange}
                  label="Vendor Email"
                  error={formik.touched.contactEmail ? formik.errors.contactEmail : ''}
                  className="bg-white"
                  //   requiredIndicator
                  disabled={false}
                />
                <CustomSelect
                  options={TradeTerms}
                  selectedOption={{
                    value: formik.values.termsOfTrade,
                    label: formik.values.termsOfTrade,
                  }}
                  handleOptionChange={(option) => {
                    formik.setFieldValue('termsOfTrade', option?.value);
                  }}
                  label="Terms of Trade"
                  name="termsOfTrade"
                  isSearchable
                  className="h-[2.7rem] rounded-lg bg-[#FFFFFF8C]"
                  error={formik.touched.termsOfTrade ? formik.errors.termsOfTrade : ''}
                  requiredIndicator
                  disabled={false}
                />
              </div>

              <div className="grid flex-1 grid-cols-1 gap-x-5 gap-y-2 sm:grid-cols-2">
                <TextField
                  name="contactName"
                  type="text"
                  value={formik.values.contactName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  label="Contact Name"
                  error={formik.touched.contactName ? formik.errors.contactName : ''}
                  className="bg-white"
                  requiredIndicator
                  style="max-[640px]:mt-4"
                  disabled={false}
                />
                <TextField
                  name="contactPhone"
                  type="tel"
                  value={formik.values.contactPhone.toString()}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const cleanInput = inputValue.replace(/[^0-9+]/g, '');
                    formik.setFieldValue('contactPhone', cleanInput);
                  }}
                  onBlur={formik.handleBlur}
                  label="Contact Number"
                  error={formik.touched.contactPhone ? formik.errors.contactPhone : ''}
                  className="bg-white"
                  disabled={false}
                />
              </div>
              <div className="grid flex-1 grid-cols-1 gap-x-5 gap-y-2 sm:grid-cols-2">
                <TextField
                  name="goodsSupplied"
                  type="text"
                  value={formik.values.goodsSupplied}
                  onChange={formik.handleChange}
                  onBlur={formik.handleChange}
                  label={'Good Bought/ Supplied'}
                  error={formik.touched.goodsSupplied ? formik.errors.goodsSupplied : ''}
                  className="bg-white"
                  requiredIndicator
                  style="max-[640px]:mt-4"
                />
                <CustomSelect
                  options={[
                    { value: 'Supplier', label: 'Supplier' },
                    { value: 'Buyer', label: 'Buyer' },
                  ]}
                  selectedOption={{
                    value: formik.values.type,
                    label: formik.values.type,
                  }}
                  handleOptionChange={(option) => {
                    formik.setFieldValue('type', option?.value);
                  }}
                  label="Vendor Type"
                  name="type"
                  isSearchable
                  className="h-[2.7rem] rounded-lg mb-6 bg-[#FFFFFF8C]"
                  error={formik.touched.type ? formik.errors.type : ''}
                  requiredIndicator
                />
              </div>

              <Button
                type="submit"
                variant="primary"
                size="lg"
                label="Submit"
                className="mt-12 "
                onClick={() => {
                  formik.values;
                }}
                loading={formik.isSubmitting}
                disabled={formik.isSubmitting || isSubmitting}
              />
            </form>
          </div>
        </div>
      </div>
    </InfoModal>
  );
}
