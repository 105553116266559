import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { resetAccountState } from '../reducers/account.reducer';
import { LoginPayloadDto, LoginUser } from '../types/user.type';
import { AppDispatch } from '../store/store';
import {
  resetBusinessState,
  setBusiness,
  setPermission,
} from '../reducers/business.reducer';
import { resetOnboardingFormState } from '../reducers/onboarding.reducer';
import { checkForTwoFa, getBussiness, getPermission } from '../service/business';
import { resendVerificationEmail } from '../service/authentication';
import { baseService } from '../service';

interface ErrorResponse {
  errors: string[];
}

export const login = createAsyncThunk<LoginUser, LoginPayloadDto>(
  'auth/login',
  async ({ email, password, dispatch, isAlreadyLogin }, { rejectWithValue }) => {
    try {
      const response = await baseService.post('/auth/user/login', {
        email,
        password,
      });
      if (isAlreadyLogin) {
        dispatch(logout());
      }
      localStorage.setItem('accessToken', response.data.data.tokens.accessToken);
      localStorage.setItem('refreshToken', response.data.data.tokens.refreshToken);
      const data: LoginUser = (response?.data || {})?.data;
      if (data.user.verifiedEmail) {
        const business = await getBussiness();
        if (business.docs.length >= 1 && business.docs[0].businessID?._id) {
          const checkForTwoFA = await checkForTwoFa(business.docs[0].businessID?._id);
          if (checkForTwoFA.is2faEnabled) {
            window.location.href = `/auth/login/otp/${business.docs[0].email}`;
          }
        }
        if (business.docs.length >= 1) {
          const permission = await getPermission(business.docs[0].businessID._id);
          dispatch(setBusiness(business));
          dispatch(setPermission(permission));
        }
        if (business.docs.length >= 1) {
          window.location.href = '/dashboard';
        } else {
          window.location.href = '/onboarding/contact';
        }
      } else {
        resendVerificationEmail();
        window.location.href = '/email-confirmation?search=verify-account';
      }
      return data;
    } catch (error) {
      const err = error as AxiosError<ErrorResponse>;
      if (!err.response) {
        throw err;
      }
      const message = err?.response?.data?.errors.join(',');
      return rejectWithValue(message);
    }
  }
);

export const logout = () => (dispatch: AppDispatch) => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  dispatch(resetAccountState());
  dispatch(resetBusinessState());
  dispatch(resetOnboardingFormState());
};

export const logoutAdmin = () => (dispatch: AppDispatch) => {
  localStorage.removeItem('admin');
  localStorage.removeItem('adminToken');
  localStorage.removeItem('adminRefreshn');
  dispatch(resetAccountState());
  dispatch(resetBusinessState());
  dispatch(resetOnboardingFormState());
};
