export const thirtyDays = '30 days';
export const sixtyDays = '60 days';
export const ninetyDays = '90 days';
export const others = 'others';
export const Supplier = 'Supplier';
export const Buyer = 'Buyer';
export const EmptyString = '';

export const TradeTerms = [
  {
    value: thirtyDays,
    label: thirtyDays,
  },
  {
    value: sixtyDays,
    label: sixtyDays,
  },
  {
    value: ninetyDays,
    label: ninetyDays,
  },
  {
    value: others,
    label: others,
  },
];
