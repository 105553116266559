import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../../../Components/Breadcrumb';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import { determinePathName } from '../../../../utils';
import { RiMenuFoldLine, RiMenuUnfoldLine } from 'react-icons/ri';
import { AnimatePresence } from 'framer-motion';
import { motion } from 'framer-motion';

const items = [
  { id: 1, path: '/settings', name: 'General' },
  { id: 2, path: 'compliance', name: 'Compliance' },
  { id: 3, path: 'department', name: 'Department' },
  { id: 4, path: 'collaborators', name: 'Collaborators' },
  { id: 5, path: 'approval', name: 'Approval' },
  { id: 6, path: 'security', name: 'Security' },
  // { id: 6, path: 'account', name: 'Subscription' },
];

export default function Settings() {
  const { pathname } = useLocation();

  const renderTabNav = () => {
    return items.map((item) => (
      <NavLink
        key={item.id}
        to={item.path}
        className={`font-medium font-inter p-3.5 py-2.5 block rounded-md text-center ${
          determinePathName(pathname) === item.path
            ? 'bg-settingsBg text-blueTextColor'
            : 'text-black/60 text-sm'
        }`}
      >
        <span className="block mr-3 md:text-base text-xs">{item.name}</span>
      </NavLink>
    ));
  };
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(false);
  }, [pathname]);

  return (
    <div>
      <div className="flex items-center justify-between">
        <Breadcrumb text="Settings" />
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="min-[580px]:hidden max-[580px]:flex px-2 pb-2"
        >
          <AnimatePresence>
            {isOpen ? (
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                {<RiMenuUnfoldLine size={22} />}
              </motion.span>
            ) : (
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <RiMenuFoldLine size={22} />
              </motion.span>
            )}
          </AnimatePresence>
        </button>
      </div>
      <div className="panel mt-5 overflow-x-auto relative rounded-[20px] bg-white">
        <div className="relative flex items-start justify-center">
          <div
            className={` ${
              isOpen
                ? 'max-[580px]:fixed left-[0vw] top-[115px] z-10 pt-[2]'
                : 'max-[580px]:fixed left-[-80vw] top-[115px] z-10 pt-[2]'
            } transition-all duration-700 ease-in-out bg-white flex flex-col gap-5 border-r p-5 min-[580px]:pt-12 `}
          >
            {renderTabNav()}
          </div>
          <Outlet />
        </div>
      </div>
    </div>
  );
}
