import React, { useEffect } from 'react';
import { checkForTwoFa, updateOtp } from '../../../../../service/business';
import { useAppSelector } from '../../../../../store/hooks';
import { selectBusiness } from '../../../../../selectors/business-selector';
import { toast } from 'react-toastify';

export default function Security() {
  const [check, setCheck] = React.useState(false);
  const { docs } = useAppSelector(selectBusiness);

  useEffect(() => {
    const checkForTwoFA = async () => {
      if (docs.length >= 1 && docs[0]?.businessID?._id) {
        const checkForTwoFA = await checkForTwoFa(docs[0].businessID._id);
        setCheck(checkForTwoFA.is2faEnabled);
      }
    };
    checkForTwoFA();
  }, []);

  const handleCheck = async (value: boolean) => {
    const response = await updateOtp(docs[0]?.businessID?._id, { is2faEnabled: value });
    if (response.success) {
      toast.success(response.message);
    } else {
      toast.error('Failed to enable OTP');
    }
  };

  return (
    <div className="w-full overflow-auto">
      <div className="border-b min-[580px]:px-9 max-[580px]:px-4 py-5">
        <p className="font-semibold text-2xl font-inter text-black">Security</p>
      </div>
      <div className="py-8 min-[580px]:px-8 max-[590px]:p-4 space-y-7 mb-52">
        <h2 className="font-semibold text-xl font-inter text-black">
          Two Factor Authentication
        </h2>
        <div className="my-10 overflow-x-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]">
          <table>
            <thead className="bg-[#0353A4B0] text-white rounded mb-3">
              <tr>
                <th className="">Type</th>
                <th className="text-center">Status</th>
                <th className=" text-center">Action</th>
              </tr>
            </thead>
            <tbody className="bg-[#B9D6F224]">
              <tr className="py-3">
                <td className="whitespace-nowrap">Email OTP</td>
                <td className="whitespace-nowrap text-center min-w-[170px]">
                  {check ? (
                    <div className="flex justify-center items-center gap-2">
                      <p className="h-3 w-3 rounded-full bg-green-500"></p>
                      <span>Enabled</span>
                    </div>
                  ) : (
                    <div className="flex justify-center items-center gap-2">
                      <p className="h-3 w-3 rounded-full bg-red-500"></p>
                      <span>Disabled</span>
                    </div>
                  )}
                </td>
                <td className="whitespace-nowrap text-center min-w-[170px]">
                  <label className="inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      checked={check ? true : false}
                      className="sr-only peer"
                      onClick={() => {
                        setCheck(!check);
                        handleCheck(check ? false : true);
                      }}
                    />
                    <div className="relative w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600 dark:peer-checked:bg-blue-600"></div>
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
