import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  createEmployee,
  createBusiness,
  getBankDetails,
  getBanks,
  getBussiness,
  getEmployee,
  getPermission,
  updateBusiness,
  updateBusinessLogo,
  updateEmployee,
  ReInviteEmployee,
} from '../../service/business';
import { toast } from 'react-toastify';
import { useAppDispatch } from '../../store/hooks';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../thunks/account-thunk';
import { resetOnboardingFormState } from '../../reducers/onboarding.reducer';
import { setBusiness, setPermission } from '../../reducers/business.reducer';
import { resetAccountState } from '../../reducers/account.reducer';

type EmployeeDto = {
  email: string;
  firstName: string;
  lastName: string;
  department: string;
  role: string;
  businessId: string;
  employeeId?: string;
};

type ImgRes = {
  url: string;
};

export const useGetBussiness = ({ isAuthenticated }: { isAuthenticated: boolean }) => {
  const dispatch = useAppDispatch();

  return useQuery(
    ['business'],
    async () => {
      const business = await getBussiness();
      const permission = await getPermission(business.docs[0].businessID._id);
      dispatch(setBusiness(business));
      dispatch(setPermission(permission));
      return business;
    },
    {
      refetchOnWindowFocus: false,
      enabled: isAuthenticated,
    }
  );
};

export const useGetPermission = () =>
  useQuery(['get-permissions'], () => getPermission(2), {
    refetchOnWindowFocus: false,
  });

export const useGetBanks = () =>
  useQuery(['banks'], () => getBanks(), {
    refetchOnWindowFocus: false,
  });

export const useGetBankAccountDetails = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload: { accountNumber: string; bankCode: string }): Promise<void> => {
      return getBankDetails(payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['bank-account-details']);
      },
      onError: (error: Error) => {
        toast.error(error.message);
      },
    }
  );
};

export const useCreateBusiness = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useMutation(
    (payload: any): Promise<void> => {
      return createBusiness(payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['create-business']);
      },
      onError: (error: Error) => {
        toast.error(
          error.message
            ? error.message
            : 'Sorry!, an error occurred while trying to create a business'
        );
        if (error.message === 'Session expired please login again') {
          dispatch(logout());
          dispatch(resetOnboardingFormState());
          navigate('/auth/login');
        }
      },
    }
  );
};

export const useUpdateBusiness = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useMutation(
    ({ payload, businessId }: { payload: any; businessId: string }): Promise<void> => {
      return updateBusiness(businessId, payload);
    },
    {
      onSuccess: async () => {
        const business = await getBussiness();
        dispatch(setBusiness(business));
        queryClient.invalidateQueries(['update-business']);
      },
      onError: (error: Error) => {
        toast.error(error.message);
        if (error.message === 'Session expired please login again') {
          dispatch(logout());
          navigate('/auth/login');
        }
      },
    }
  );
};

export const useGetEmployee = ({ businessId }: { businessId: string }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useQuery(['employee'], () => getEmployee(businessId), {
    refetchOnWindowFocus: false,
    enabled: !!businessId,
    onError: (error: Error) => {
      if (error.message === 'Session expired please login again') {
        dispatch(logout());
        navigate('/auth/login');
      }
    },
  });
};

export const useCreateEmployee = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useMutation(
    ({
      email,
      firstName,
      lastName,
      department,
      role,
      businessId,
    }: EmployeeDto): Promise<void> => {
      return createEmployee(businessId, { email, firstName, lastName, department, role });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['create-employee']);
      },
      onError: (error: Error) => {
        toast.error(error.message);
        if (error.message === 'Session expired please login again') {
          dispatch(logout());
          navigate('/auth/login');
        }
      },
    }
  );
};

export const useUpdateEmployee = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useMutation(
    ({
      email,
      firstName,
      employeeId,
      lastName,
      department,
      role,
      businessId,
    }: EmployeeDto): Promise<void> => {
      return updateEmployee(businessId, employeeId, {
        email,
        firstName,
        lastName,
        department,
        role,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['update-employee']);
      },
      onError: (error: Error) => {
        toast.error(error.message);
        if (error.message === 'Session expired please login again') {
          dispatch(logout());
          dispatch(resetOnboardingFormState());
          dispatch(resetAccountState());
          navigate('/auth/login');
        }
      },
    }
  );
};

export const useReInviteEmployee = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useMutation(
    ({ businessID, email }: { businessID: string; email: string }): Promise<void> => {
      return ReInviteEmployee(businessID, email);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['ReInvite-employee']);
      },
      onError: (error: Error) => {
        toast.error(error.message);
        if (error.message === 'Session expired please login again') {
          dispatch(logout());
          dispatch(resetAccountState());
          navigate('/auth/login');
        }
      },
    }
  );
};

export const useUpdateBusinessLogo = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useMutation(
    (payload: any): Promise<ImgRes> => {
      return updateBusinessLogo(payload.businessId, payload.form);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['update-employee']);
      },
      onError: (error: Error) => {
        toast.error(error.message);
        if (error.message === 'Session expired please login again') {
          dispatch(logout());
          dispatch(resetAccountState());
          navigate('/auth/login');
        }
      },
    }
  );
};
