import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  createDepartment,
  createRole,
  getApproval,
  getDepartment,
  getPermissionList,
  getPerson,
  getRole,
  togglePersonToApproval,
  updateDepartment,
  updateRole,
} from '../../service/settings';
import { toast } from 'react-toastify';
import { useAppDispatch } from '../../store/hooks';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../thunks/account-thunk';
import { ApprovalDto, BlockDto, IResponse } from '../../types/approval.type';
import { archiveADept, deleteLevel } from '../../Api/apiCalls';
export const useGetDepartment = ({
  businessId,
  page,
}: {
  businessId: string;
  page: any;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useQuery(
    ['department'],
    async () => {
      const departments = await getDepartment(businessId, page);
      return departments;
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!businessId,
      onError: (error: Error) => {
        if (error.message === 'Session expired please login again') {
          dispatch(logout());
          navigate('/auth/login');
        }
      },
    }
  );
};

export const useCreateDepartment = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useMutation(
    ({ name, businessId }: { name: string; businessId: string }): Promise<IResponse> => {
      return createDepartment(businessId, { name });
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(['create-department']);
        return data;
      },
      onError: (error: Error) => {
        toast.error(
          error.message ??
            'Sorry!, an error has occurred while trying to create a department'
        );
        if (error.message === 'Session expired please login again') {
          dispatch(logout());
          navigate('/auth/login');
        }
      },
    }
  );
};

export const useUpdateDepartment = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useMutation(
    ({
      payload,
      businessId,
    }: {
      payload: any;
      businessId: string;
    }): Promise<IResponse> => {
      return updateDepartment(businessId, payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['update-department']);
      },
      onError: (error: Error) => {
        toast.error(
          error.message ??
            'Sorry, something has gone wrong while updating your departments'
        );
        if (error.message === 'Session expired please login again') {
          dispatch(logout());
          navigate('/auth/login');
        }
      },
    }
  );
};

export const useGetRole = ({ businessId, page }: { businessId: string; page: any }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useQuery(
    ['role'],
    async () => {
      const departments = await getRole(businessId, page);
      return departments;
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!businessId,
      onError: (error: Error) => {
        if (error.message === 'Session expired please login again') {
          dispatch(logout());
          navigate('/auth/login');
        }
      },
    }
  );
};

export const useGetPermissionList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useQuery(['permissionList'], async () => getPermissionList(), {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    onError: (error: Error) => {
      if (error.message === 'Session expired please login again') {
        dispatch(logout());
        navigate('/auth/login');
      }
    },
  });
};

export const useCreateRole = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useMutation(
    ({
      name,
      permissions,
      businessId,
    }: {
      name: string;
      permissions: string[];
      businessId: string;
    }): Promise<void> => {
      return createRole(businessId, { name, permissions });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['create-role']);
      },
      onError: (error: Error) => {
        toast.error(
          error.message ?? 'Sorry, an error has occurred while creating a role'
        );
        if (error.message === 'Session expired please login again') {
          dispatch(logout());
          navigate('/auth/login');
        }
      },
    }
  );
};

export const useUpdateRole = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useMutation(
    ({
      name,
      permissions,
      businessId,
      id,
    }: {
      name: string;
      permissions: string[];
      id: string;
      businessId: string;
    }): Promise<void> => {
      return updateRole(businessId, { name, permissions, id });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['update-role']);
      },
      onError: (error: Error) => {
        toast.error(error.message ?? 'sorry an error has occurred while updating a role');
        if (error.message === 'Session expired please login again') {
          dispatch(logout());
          navigate('/auth/login');
        }
      },
    }
  );
};

export const useGetPerson = ({
  businessId,
  params,
}: {
  businessId: string;
  params: string;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useQuery(
    ['get-person'],
    async () => {
      const persons = await getPerson(businessId, params);
      return persons;
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!businessId,
      onError: (error: Error) => {
        if (error.message === 'Session expired please login again') {
          dispatch(logout());
          navigate('/auth/login');
        }
      },
    }
  );
};

export const useGetApproval = ({ businessId }: { businessId: string }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useQuery(
    ['get-approval'],
    async () => {
      const persons = await getApproval(businessId);
      return persons;
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!businessId,
      onError: (error: Error) => {
        if (error.message === 'Session expired please login again') {
          dispatch(logout());
          navigate('/auth/login');
        }
      },
    }
  );
};

export const useAddPersonApproval = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useMutation(
    (payload: ApprovalDto): Promise<void> => {
      return togglePersonToApproval(payload.businessId, { approvals: payload.approvals });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['add-approval']);
      },
      onError: (error: Error) => {
        toast.error(error.message);
        if (error.message === 'Session expired please login again') {
          dispatch(logout());
          navigate('/auth/login');
        }
      },
    }
  );
};

export const useBlockPersonApproval = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useMutation(
    (payload: BlockDto): Promise<void> => {
      return togglePersonToApproval(payload.businessId, { blocks: payload.block });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['block-approval']);
      },
      onError: (error: Error) => {
        toast.error(
          error.message ?? 'Sorry!, an error has occurred while blocking approval'
        );
        if (error.message === 'Session expired please login again') {
          dispatch(logout());
          navigate('/auth/login');
        }
      },
    }
  );
};

export const useArchiveDept = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useMutation(
    async ({ businessId, id }: { businessId: string; id: string }) => {
      const response = await archiveADept(businessId, id);
      return response;
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(['departments']);
        return data;
      },
      onError: (error: Error) => {
        if (error.message === 'Session expired please login again') {
          dispatch(logout());
          navigate('/auth/login');
        }
      },
    }
  );
};

export const useDeleteLevel = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useMutation(
    async ({
      businessID,
      approvalType,
      level,
    }: {
      businessID: string;
      approvalType: string;
      level: string;
    }) => {
      const response = await deleteLevel(businessID, approvalType, level);
      return response;
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries([`approval-level`]);
        return data;
      },
      onError: (error: Error) => {
        if (error.message === 'Session expired please login again') {
          dispatch(logout());
          navigate('/auth/login');
        }
      },
    }
  );
};
