import { useEffect, useState } from 'react';
import Button from '../../Form/Button';
import Rating from '../../Rating';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { InfoModal } from '../InfoModal';
import { useRateTransaction } from '../../../hooks/queries-and-mutations/transaction';
import { toast } from 'react-toastify';

type Props = {
  onCancel: () => void;
  onSuccess: () => void;
  businessId: string;
  transactionId: string;
};

export default function RateTransaction({
  onCancel,
  businessId,
  transactionId,
  onSuccess,
}: Props) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { mutate, isSuccess, isError } = useRateTransaction();

  const formik = useFormik({
    initialValues: {
      rating: 0,
      review: '',
    },
    validationSchema: Yup.object({
      rating: Yup.number().required('Rating is required').nullable(),
      review: Yup.string().required('Review is required').nullable(),
    }),
    onSubmit: (values) => {
      setIsSubmitting(true);
      mutate({
        businessId,
        transactionId,
        payload: { value: values.rating, comment: values.review },
      });
    },
  });

  useEffect(() => {
    if (isSuccess && !isError) {
      toast.success('Thank for your feedback.');
      setIsSubmitting(false);
      onSuccess();
    } else if (isError) {
      setIsSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, isSuccess]);

  return (
    <InfoModal
      width="min-[1100px]:w-[45%] max-[1100px]:w-[60%] min-w-[500px] max-[650px]:min-w-[300px] max-[650px]:w-[90%]"
      className="h-fit "
      onClose={onCancel}
    >
      <div className="rounded-[20px] bg-white py-8 min-[650px]:px-8 max-[650px]:px-0 font-circular text-2xl font-normal text-[#353535]">
        <div className="flex flex-col">
          <div className="w-full mx-auto my-8">
            <div className="flex flex-col items-center justify-center space-y-10">
              <p className="text-xl font-semibold text-black font-inter">
                Rate Transaction
              </p>
              <p className="text-sm font-medium font-inter text-black/40">
                What would you rate this transaction
              </p>
            </div>

            <div className="mx-auto ">
              <form
                className="space-y-0.5 items-center justify-center flex flex-col"
                onSubmit={formik.handleSubmit}
              >
                <div className="flex flex-col items-center justify-center w-full mt-5">
                  <div className="flex flex-col items-center justify-center mb-2">
                    <Rating
                      rating={formik.values.rating}
                      onClick={(rating) => {
                        formik.setFieldValue('rating', rating);
                      }}
                      width={37}
                      height={37}
                    />
                    {formik.errors.rating && (
                      <p className="mt-2 text-xs text-red-600 capitalize">
                        {formik.errors.rating}
                      </p>
                    )}
                  </div>

                  <textarea
                    name="review"
                    placeholder="Leave a message if you want"
                    rows={5}
                    onChange={formik.handleChange}
                    onBlur={formik.handleChange}
                    className={`form-textarea max-[580px]:w-[90%] px-10 text-sm mt-5 font-inter font-normal rounded-lg  
                    ${
                      formik.touched.review && formik.errors.review
                        ? 'bg-red-50 border border-red-500 text-red-900 placeholder:text-red-700 text-sm rounded-lg focus:ring-red-500 focus:border-red-500'
                        : 'bg-white border border-[#0000001A] placeholder:text-white-dark '
                    }`}
                    defaultValue={formik.values.review}
                  />
                  {formik.errors.review && (
                    <p className="mt-2 text-xs text-red-600 capitalize">
                      {formik.errors.review}
                    </p>
                  )}

                  <Button
                    type="submit"
                    variant="primary"
                    size="lg"
                    label="Submit"
                    className="mt-10"
                    loading={isSubmitting}
                    disabled={isSubmitting}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </InfoModal>
  );
}
