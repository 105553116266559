import React from 'react';
import { TransactionDoc } from '../../../../types/transaction.type';
import { formatCurrency } from '../../../../utils';
import { format } from 'date-fns';
import { CiFileOn } from 'react-icons/ci';
import { InvoiceDoc } from '../../../../types/invoice.type';
import Button from '../../../../Components/Form/Button';

type Props = {
  history: TransactionDoc[] | null;
  invoice: InvoiceDoc | undefined;
  loading: boolean;
};

const PaymentHistory: React.FC<Props> = ({ history, loading, invoice }) => {
  const handleClick = (id: string, option: string) => {
    const index = invoice?.paymentProof?.findIndex((item) => item === id);
    if (option === 'view' && index !== undefined && index >= 0) {
      window.open(`${invoice?.fileURLs[index]}`, '_blank')?.focus();
    }
  };

  return (
    <div>
      <div className="mt-6 overflow-auto font-inter">
        <table>
          <thead className="bg-transparent text-black/70 w-full py-6 rounded mb-3">
            <tr>
              <th>Currency</th>
              <th className="text-center">Amount</th>
              <th className="text-center">Date</th>
              <th className="text-center min-w-[140px] ">Payment Status</th>
              <th className="text-center min-w-[140px] ">Payment Method</th>
              <th className="text-center min-w-[140px] ">Attachment</th>
            </tr>
          </thead>
          <tbody>
            {history && !loading ? (
              history?.map((item) => (
                <tr className=" odd:bg-white" key={item.id}>
                  <td>{item.currency}</td>
                  <td className="whitespace-nowrap px-6 py-4 text-center min-w-[200px]">
                    {formatCurrency(
                      item?.currency === 'Dollars' ? 'usd' : item?.currency,
                      item?.amount
                    )}{' '}
                  </td>
                  <td className=" whitespace-nowrap px-6 py-4 text-center min-w-[200px]">
                    {' '}
                    {format(new Date(item?.createdAt), 'dd, MMM yyyy')}
                  </td>
                  <td className=" whitespace-nowrap px-6 py-4 text-center min-w-[200px]">
                    {item?.verified ? 'Verified' : 'Not verified'}{' '}
                  </td>
                  <td className=" whitespace-nowrap px-6 py-4 text-center min-w-[200px]">
                    {item?.paymentType === 'online_payment' ? (
                      'Online'
                    ) : (
                      <span className="flex items-center justify-center gap-2 text-blueTextColor">
                        <CiFileOn size={14} /> Offline{' '}
                      </span>
                    )}{' '}
                  </td>
                  <td className="flex items-center justify-center whitespace-nowrap px-6 py-4 text-center min-w-[200px]">
                    {item?.paymentType !== 'online_payment' && (
                      <Button
                        onClick={() => handleClick(item._id, 'view')}
                        className="text-blueTextColor outline-none text-center text-sm"
                        label="View attachment"
                        variant="transparent"
                      />
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <th> </th>
                <th></th>
                <th></th>
                <th className="pt-6 text-left">Nothing to Show Yet </th>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PaymentHistory;
