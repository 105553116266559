import React, { useEffect, useRef } from 'react';
import TextField from '../../../Components/Form/TextField';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from '../../../Components/Form/Button';
import {
  getBussiness,
  getPermission,
  requestForTwoFaToken,
  validateToken,
} from '../../../service/business';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { setBusiness, setPermission } from '../../../reducers/business.reducer';
import { useAppDispatch } from '../../../store/hooks';
import { formatTime } from '../../../utils';
import useCountdown from '../../../hooks/timer';

export default function OTP() {
  const navigate = useNavigate();
  const { email } = useParams();
  const inputRefs = useRef<(HTMLInputElement | null)[]>(Array(6).fill(null));
  const dispatch = useAppDispatch();

  const { seconds, resetCountdown } = useCountdown(
    300,
    'OTP has expired. Please request a new one.'
  );

  useEffect(() => {
    const getToken = async () => {
      const response = await requestForTwoFaToken();
      if (response) {
        toast.success('An OTP has been sent to your mail');
      } else {
        toast.error(response.message);
      }
    };
    getToken();
  }, []);

  const formik = useFormik({
    initialValues: {
      otp: ['', '', '', '', '', ''],
    },
    validationSchema: Yup.object({
      otp: Yup.array().of(Yup.string().required('OTP is required')).required().nullable(),
    }),
    onSubmit: async (values) => {
      const response = await validateToken(values.otp.join(''));
      if (response.success) {
        toast.success('OTP verified successfully');
        const business = await getBussiness();
        const permission = await getPermission(business.docs[0].businessID._id);
        dispatch(setBusiness(business));
        dispatch(setPermission(permission));
        navigate('/dashboard');
      } else {
        toast.error(response.message);
      }
    },
  });

  const resendOTP = async () => {
    const response = await requestForTwoFaToken();
    if (response.success) {
      resetCountdown(300);
      toast.success('An OTP has been sent to your mail');
      formik.setFieldValue('otp', ['', '', '', '', '', '']);
    } else {
      toast.error(response?.message ?? 'Sorry! something has gone wrong.');
    }
  };

  const handleChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = event.target;
    formik.handleChange(event);

    if (value.length === 1 && index < 5 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1]!.focus();
    }
    if (value.length === 0 && index > 0 && inputRefs.current[index - 1]) {
      inputRefs.current[index - 1]!.focus();
    }
  };

  return (
    <div className="flex items-center justify-center h-screen w-screen">
      <div className="max-w-[400px] w-[90%]">
        <div className="flex flex-col items-center justify-center">
          <h2 className="text-lg font-semibold">Enter OTP </h2>
          <p className="text-sm text-center mt-1">We just sent an OTP to {email}</p>
          <p className="text-sm mb-4">Enter it below.</p>
        </div>

        <form
          onSubmit={formik.handleSubmit}
          className="bg-subscriptionBg py-8 px-8 rounded-lg"
        >
          <div className="flex flex-wrap items-center justify-center gap-4">
            {Array.from({ length: 6 }).map((_, index) => (
              <TextField
                key={index}
                name={`otp[${index}]`}
                type="password"
                value={formik.values.otp[index]}
                onChange={(e) => {
                  formik.setFieldValue(`otp[${index}]`, e.target.value);
                  handleChange(index, e);
                }}
                onBlur={formik.handleChange}
                className="w-10 h-10 text-center"
                maxLength={1}
                inputRef={(ref) => (inputRefs.current[index] = ref)}
              />
            ))}
          </div>
          <p className=" mb-4 text-end">
            {formatTime(seconds)} <span className="ml-[2px] text-black/40"> secs</span>{' '}
          </p>
          <Button
            type="submit"
            variant="primary"
            size="md"
            label="Verify"
            className="w-full"
            loading={formik.isSubmitting}
            disabled={formik.isSubmitting}
          />
          <p className="text-black/40 mt-4 text-center">
            Did't receive OTP code?{' '}
            <span
              role="button"
              tabIndex={0}
              className="text-darkedBtnBg"
              onClick={() => resendOTP()}
            >
              Resend{' '}
            </span>{' '}
          </p>
        </form>
      </div>
    </div>
  );
}
