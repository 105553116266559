import { InvoiceDoc } from '../../types/invoice.type';
import { formatCurrency } from '../../utils';
import Button from '../Form/Button';
import FileUpload from '../Form/FileUpload';
import TextField from '../Form/TextField';
import { InfoModal } from './InfoModal';

type Props = {
  title?: string;
  content: string;
  actionText?: string;
  cancelText?: string;
  onConfirm: () => void;
  onCancel: () => void;
  isSubmitting?: boolean;
  cancelClassName?: string;
  actionClassName?: string;
  widthClass?: number;
  formik?: any;
  showInput?: false;
  vendorName?: string;
  invoice: InvoiceDoc;
  markFromSeller?: boolean;
};

export default function ProofModal({
  content,
  title,
  actionText,
  cancelText,
  onConfirm,
  onCancel,
  isSubmitting,
  cancelClassName,
  actionClassName,
  widthClass = 45,
  formik,
  invoice,
  markFromSeller = false,
}: Props) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Remove commas for conversion to number
    const numericValue = parseFloat(e.target.value.replace(/,/g, ''));
    if (!isNaN(numericValue)) {
      const formattedValue = formatNumberWithCommas(numericValue);
      formik.setFieldValue('amount', formattedValue);
    } else {
      formik.setFieldValue('amount', '');
    }
  };

  const formatNumberWithCommas = (value: number) => {
    return new Intl.NumberFormat().format(value);
  };

  return (
    <InfoModal
      width={`w-[${widthClass}%] max-[1100px]:w-[70%] max-[780px]:w-[90%] `}
      className="h-fit "
      onClose={onCancel}
    >
      <div className="rounded-[20px] bg-white py-8 min-[600px]:px-8 max-[600px]:px-2 font-circular text-2xl font-normal text-[#353535]">
        <div className="flex flex-col justify-between">
          <h2 className="scroll-m-20 min-[600px]:text-xl max-[600px]:text-[18px] font-semibold tracking-tight first:mt-0">
            {title}{' '}
          </h2>
          <p className="text-sm mt-2 mb-4 pb-2 font-medium font-inter border-b border-black/40 text-black/40">
            {`Confirm payment of ${formatCurrency(invoice?.currency, invoice?.amount)} ${
              markFromSeller ? 'from' : 'to'
            } ${markFromSeller ? invoice?.sellerID?.name : invoice?.businessID?.name}`}
          </p>
          <TextField
            name="amount"
            type="text"
            placeholder={
              markFromSeller
                ? formatCurrency(invoice?.currency, invoice?.amount)
                : 'Enter amount paid.'
            }
            value={formik.values.amount}
            onChange={handleChange}
            onBlur={formik.handleChange}
            label="Amount Paid"
            error={formik.touched.amount ? formik.errors.amount : ''}
            className=""
            requiredIndicator={markFromSeller ? false : true}
            style="mt-2"
            disabled={markFromSeller}
          />

          <div className="w-full max-[600px]:text-[24px] text-center">
            <p className="text-xl font-medium font-inter">{content}</p>
            <div className="flex flex-col items-center gap-3 ">
              {markFromSeller ? (
                invoice?.fileURLs.length > 0 ? (
                  <a
                    target="_blank"
                    href={invoice?.fileURLs[0]}
                    rel="noopener noreferrer"
                  >
                    <img src={invoice?.fileURLs[0]} alt="payment proof" />
                  </a>
                ) : (
                  <p className="text-sm">No Payment Proof uploaded yet.</p>
                )
              ) : (
                <FileUpload
                  error={formik.errors.proof ? 'Payment proof is required' : ''}
                  label={markFromSeller ? 'Uploaded Receipt.' : 'Upload Receipts'}
                  fileName={formik.values.receipt ?? ''}
                  getFile={(file) => {
                    formik.setFieldValue('proof', file);
                  }}
                  requiredIndicator={markFromSeller ? false : true}
                  style="mx-auto flex flex-col items-center w-full"
                />
              )}
            </div>
          </div>
          <div
            className={`flex ${
              markFromSeller ? 'flex-row-reverse' : ''
            }  max-[480px]:flex-wrap items-center justify-center gap-3 w-full my-3 mt-10`}
          >
            <Button
              variant="custom"
              size="custom"
              className={cancelClassName}
              label={cancelText || 'No'}
              onClick={onCancel}
              disabled={isSubmitting}
              loading={isSubmitting}
            ></Button>
            <Button
              variant="custom"
              size="custom"
              className={actionClassName}
              label={actionText || 'Yes'}
              onClick={onConfirm}
              disabled={isSubmitting}
              loading={isSubmitting}
            ></Button>
          </div>
        </div>
      </div>
    </InfoModal>
  );
}
