import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

const useCountdown = (initialSeconds: number, toastMessage: string) => {
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    if (seconds <= 0) return;

    const countdown = setInterval(() => {
      setSeconds((prev) => {
        if (prev <= 1) {
          clearInterval(countdown);
          toast.warning(toastMessage);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(countdown);
  }, [seconds]);

  const resetCountdown = (newSeconds: number) => {
    setSeconds(newSeconds);
  };

  return { seconds, resetCountdown };
};

export default useCountdown;
