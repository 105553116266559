import { useState } from 'react';
import './Auth.scss';
import Loader from '../../Assests/loader.gif';
import Image from '../../Assests/Group 3465459.png';
import { ReactComponent as Enter } from '../../Assests/Enter.svg';
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import axios from 'axios';

type FormData = {
  password: string;
  confirmPassword: string;
};
interface Props {
  notify: (type: string, message: string) => void;
}
function RestPass({ notify }: Props) {
  const { key, token } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const navigate = useNavigate();
  const [formLoading, setFormLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<FormData>();

  const onSubmit = async (data: FormData) => {
    setFormLoading(true);
    const payLoad = {
      key: key,
      token: token,
      newPassword: data.confirmPassword,
    };
    try {
      const baseUrl = process.env.REACT_APP_API_URL; // Get the base URL from the environment variable
      const response = await axios.post(`${baseUrl}/auth/password/forgot`, payLoad);
      notify('success', response.data.message);
      setFormLoading(false);
      setTimeout(() => {
        navigate('/auth/login');
      }, 2000);
    } catch (error) {
      setFormLoading(false);
      if (axios.isAxiosError(error)) {
        notify('error', error.response?.data.errors.join(',') || 'An error occurred');
      } else {
        notify('error', 'An unexpected error occurred');
      }
    }
  };

  const validatePassword = (value: string) => {
    // eslint-disable-next-line no-useless-escape
    const isValid = /^[a-zA-Z0-9-!@#$%^&*()_+\-=\[\]{}|;':",.<>\/?]+$/.test(value);
    return (
      isValid || 'Password should be alphanumeric and may contain special characters'
    );
  };

  return (
    <div className="auth">
      <div className="register">
        <div className="right">
          <img src={Image} alt="" />
        </div>
        <div className="left">
          <div className="left-body">
            <h2>Password Change</h2>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="cards">
              <p> Password</p>
              <div className="input relative">
                <input
                  type={showPassword ? 'text' : 'password'}
                  {...register('password', {
                    required: true,
                    minLength: 6,
                    validate: validatePassword,
                  })}
                  id=""
                />
                <span className="absolute right-4 top-1/4">
                  {showPassword ? (
                    <AiOutlineEye onClick={() => setShowPassword(false)} />
                  ) : (
                    <AiOutlineEyeInvisible onClick={() => setShowPassword(true)} />
                  )}
                </span>
              </div>
              {errors.password && errors.password.type === 'required' && (
                <span>This field is required</span>
              )}
              {errors.password && errors.password.type === 'minLength' && (
                <span>Password must be at least 6 characters</span>
              )}
              {errors.password && errors.password.type === 'validate' && (
                <span>{errors.password.message}</span>
              )}
            </div>
            <div className="cards">
              <p> Confirm Password</p>
              <div className="input relative">
                <input
                  type={showPassword2 ? 'text' : 'password'}
                  onPaste={(e) => e.preventDefault()}
                  {...register('confirmPassword', {
                    required: true,
                    validate: (value) =>
                      value === getValues('password') || 'Passwords do not match',
                  })}
                  id=""
                />
                <span className="absolute right-4 top-1/4">
                  {showPassword2 ? (
                    <AiOutlineEye onClick={() => setShowPassword2(false)} />
                  ) : (
                    <AiOutlineEyeInvisible onClick={() => setShowPassword2(true)} />
                  )}
                </span>
              </div>
              {errors.confirmPassword && errors.confirmPassword.type === 'required' && (
                <span>This field is required</span>
              )}
              {errors.confirmPassword && errors.confirmPassword.type === 'validate' && (
                <span>{errors.confirmPassword.message}</span>
              )}
            </div>
            <div className="card">
              {formLoading ? (
                <div className="loader">
                  <img src={Loader} alt="" />
                </div>
              ) : (
                <button type="submit">
                  <p>Change Password</p>
                  <Enter />
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default RestPass;
