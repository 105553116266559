import React, { useMemo, useRef, useState } from 'react';
import { InvoiceDoc, InvoiceStatus } from '../../../types/invoice.type';
import { useNavigate } from 'react-router-dom';
import { deleteAnInvoice, markInvoice } from '../../../service/invoice';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import withCreatePortal from '../../Hoc/withCreatePortal';
import ConfirmPaymentModal from '../ConfirmPaymentModal';
import ProofModal from '../ProofModal';
import DeleteModal from '../DeleteModal';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { motion } from 'framer-motion';
import useOnClickOutside from '../../../CustomHooks/useClickOutside';
import { useVerifyInvoice } from '../../../hooks/queries-and-mutations/invoice';
import { useGlobalContext } from '../../../Context/Context';

interface IProps {
  className?: string;
  onClick?: () => void;
  invoice?: InvoiceDoc | null;
  businessId?: string;
  name?: string;
  email: string;
  setInvoiceData: (arg: any) => void;
  visibility: string;
  setActiveIndex: (arg: any) => void;
  setRunAfterPayment: (arg: boolean) => void;
}

const EnhancedConfirmModal = withCreatePortal(ConfirmPaymentModal);
const EnhancedReceiptModal = withCreatePortal(ProofModal);
const EnhancedDeleteModal = withCreatePortal(DeleteModal);

const Action: React.FC<IProps> = ({
  invoice,
  businessId,
  setInvoiceData,
  visibility,
  email,
  setActiveIndex,
  setRunAfterPayment,
}) => {
  const navigate = useNavigate();
  const [confirmationModalActive, setConfirmationModalActive] = useState<boolean>(false);
  const [proceedWithPayment, setProceedWithPayment] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [markInvoiceModal, setMarkInvoiceModal] = useState<boolean>(false);
  const [markInvoiceForSeller, setMarkInvoiceFromSeller] = useState<boolean>(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const modalRef = useRef(null);
  const { setSelectedInvoiceId } = useGlobalContext();

  const { mutateAsync, isLoading } = useVerifyInvoice();

  const deleteInvoiceId = async (businessId: any, invoiceId: string | undefined) => {
    setIsSubmitting(true);
    await deleteAnInvoice(businessId, (invoiceId && invoiceId) || '')
      .then(() => {
        toast.success('Invoice deleted successfully');
        setInvoiceData((prev: any) => ({
          ...prev,
          docs: prev.docs.filter((res: any) => res?._id !== invoiceId),
        }));
        setActiveIndex('');
        setIsSubmitting(false);
      })
      .catch((error) => {
        const err = error as AxiosError<Error>;
        toast.error(err?.message ?? 'Sorry, An error as occurred');
      });
  };

  //! This is temporal until it's decided
  // const sendARemainder = async (businessId: any, invoiceId: string | undefined) => {
  //   const payload = {
  //     email: `${invoice?.email}`,
  //     cc: [
  //       {
  //         email: `${email}`,
  //       },
  //     ],
  //     message: `This is a reminder your Invoice ${invoice?.number} sent to ${name} is now overdue. Log-in to view the invoice and send a reminder. You can also confirm payment if you have received payment for this invoice. Thank you, Paytton `,
  //   };
  //   if (invoiceId) {
  //     await sendRemainder(businessId, invoiceId, payload)
  //       .then(() => {
  //         toast.success('A remainder has been sent successfully');
  //       })
  //       .catch((error) => {
  //         const err = error as AxiosError<Error>;
  //         toast.error(err.message ?? 'Sorry, An error as occurred');
  //       });
  //   }
  // };

  const formik = useFormik({
    initialValues: {
      proof: null,
      amount: '',
    },
    validationSchema: Yup.object({
      proof: Yup.mixed().required().nullable(),
      amount: Yup.string()?.required().nullable(),
    }),
    onSubmit: async (values) => {
      if (values.proof && values.amount && businessId && invoice?._id) {
        const formData = new FormData();
        formData.append('proof', values.proof);
        const amount = values.amount.replace(/,/g, '');
        formData.append('amount', amount);
        await markInvoice(businessId, invoice?._id, formData)
          .then(() => {
            toast.success('Invoice marked as paid successfully');
            setMarkInvoiceFromSeller(false);
            setMarkInvoiceModal(false);
          })
          .catch((error) => {
            const err = error as AxiosError<Error>;
            toast.error(err?.message ?? 'Sorry!, An error as occurred');
          });
      }
    },
  });

  const handleModalClose = () => {
    setActiveIndex(null);
  };

  useOnClickOutside(
    confirmationModalActive ||
      markInvoiceModal ||
      markInvoiceForSeller ||
      deleteConfirmation
      ? modalRef.current
      : modalRef,
    handleModalClose
  );

  const shouldShowButton = useMemo(() => {
    const isApproved = visibility === InvoiceStatus.APPROVED;
    const isSellerInternalApproved =
      visibility === InvoiceStatus.SELLER_INTERNAL_APPROVED;
    const isAwaitingCustomerApproval =
      visibility === InvoiceStatus.AWAITING_APPROVAL_BUYER;

    return isApproved || isSellerInternalApproved || isAwaitingCustomerApproval;
  }, [visibility]);

  const handleMarkAsPaidForSeller = async () => {
    mutateAsync({
      businessId: businessId ? businessId : '',
      reference: invoice?.paymentProof[0],
    })
      .then(() => {
        setMarkInvoiceFromSeller(false);
        toast.success('Payment proof confirmed successfully');
        setRunAfterPayment(true);
      })
      .catch((error) => {
        const err = error as AxiosError<Error>;
        toast.error(err?.message ?? 'Sorry!, An error as occurred');
      });
  };

  return (
    <div>
      <motion.div
        ref={modalRef}
        className={`${confirmationModalActive && 'opacity-0'} ${
          visibility === InvoiceStatus.paid
            ? 'absolute left-[-70px] bottom-[-50px] z-[1] flex flex-col justify-center items-center panel bg-white w-[165px]'
            : visibility === InvoiceStatus.DRAFT
            ? 'absolute left-[-70px] bottom-[-175px] z-[1] flex flex-col justify-center items-center panel bg-white w-[165px] '
            : visibility === InvoiceStatus.APPROVED &&
              businessId === invoice?.businessID?._id
            ? 'absolute left-[-100px] bottom-[-105px] z-[1] flex flex-col justify-center items-center panel bg-white w-[165px]'
            : visibility === InvoiceStatus.APPROVED &&
              businessId === invoice?.sellerID?._id
            ? 'absolute left-[-90px] bottom-[-175px] z-[1] flex flex-col justify-center items-center panel  bg-white w-[165px]'
            : visibility === InvoiceStatus.SELLER_INTERNAL_APPROVED &&
              businessId === invoice?.sellerID?._id
            ? 'absolute left-[-90px] bottom-[-170px] z-[1] flex flex-col justify-center items-center panel bg-white w-[165px]'
            : visibility === InvoiceStatus.AWAITING_APPROVAL_BUYER &&
              businessId === invoice?.sellerID?._id
            ? 'absolute left-[-100px] bottom-[-170px] z-[1]  flex flex-col justify-center items-center panel bg-white w-[165px]'
            : visibility === InvoiceStatus.SELLER_INTERNAL_APPROVED &&
              businessId === invoice?.businessID?._id
            ? 'absolute left-[-100px] bottom-[-110px] z-[1]  flex flex-col justify-center items-center panel bg-white w-[165px]'
            : visibility === InvoiceStatus.AWAITING_APPROVAL_BUYER &&
              businessId === invoice?.businessID?._id
            ? 'absolute left-[-90px] bottom-[-100px] z-[1] flex flex-col justify-center items-center panel bg-white w-[165px]'
            : visibility === InvoiceStatus.AWAITING_APPROVAL_SELLER &&
              businessId === invoice?.businessID?._id
            ? 'absolute left-[-100px]  bottom-[-50px] z-[1] flex flex-col justify-center items-center panel bg-white w-[165px]'
            : 'absolute left-[-90px] bottom-[-50px] z-[1] flex flex-col justify-center items-center panel bg-white w-[165px]'
        }`}
        initial={{ opacity: 0 }}
        animate={{
          position: 'absolute',
          zIndex: 3,
          opacity: confirmationModalActive || markInvoiceModal ? 0 : 1,
          backgroundColor: 'white',
        }}
        exit={{ opacity: 0 }}
      >
        <ul className=" flex flex-col gap-3 w-[100%]">
          <li
            onClick={() => {
              navigate(`/invoices/view/${invoice?._id}`);
            }}
            className="py-4 hover:bg-primary hover:text-white transition duration-1000 ease-out cursor-pointer"
          >
            {' '}
            <button className="px-4 "> View </button>
          </li>
          {visibility === InvoiceStatus.draft && (
            <li
              onClick={() => {
                setSelectedInvoiceId(invoice?._id);
                navigate(`/invoices/create`);
              }}
              className="py-4 hover:bg-primary hover:text-white transition duration-1000 ease-out"
            >
              <button className="px-4">Edit</button>{' '}
            </li>
          )}

          {shouldShowButton && businessId === invoice?.sellerID?._id ? (
            <li className="py-4 hover:bg-primary hover:text-white transition duration-1000 ease-out">
              <button
                className="px-4 "
                onClick={() => {
                  setMarkInvoiceModal(true);
                }}
              >
                Mark as paid{' '}
              </button>{' '}
            </li>
          ) : shouldShowButton && businessId === invoice?.businessID?._id ? (
            <li className="py-4 hover:bg-primary hover:text-white transition duration-1000 ease-out">
              <button
                className="px-4 "
                onClick={() => {
                  setMarkInvoiceFromSeller(true);
                }}
              >
                Mark as paid{' '}
              </button>{' '}
            </li>
          ) : (
            ''
          )}

          {shouldShowButton && businessId === invoice?.sellerID?._id && (
            <li className="py-4 relative z-40 hover:bg-primary hover:text-white transition duration-1000 ease-out">
              <button
                onClick={() => {
                  setConfirmationModalActive(true);
                }}
                className="px-4"
              >
                Make payment{' '}
              </button>{' '}
            </li>
          )}

          {visibility === InvoiceStatus.draft && (
            <li className="py-4 hover:bg-primary hover:text-white transition duration-1000 ease-out">
              <button
                onClick={() => {
                  setDeleteConfirmation(true);
                }}
                className="px-4"
              >
                Delete{' '}
              </button>{' '}
            </li>
          )}
        </ul>
      </motion.div>

      {deleteConfirmation && (
        <EnhancedDeleteModal
          title="Delete An Invoice"
          content={`This Invoice for ${invoice?.sellerName} would be deleted `}
          invoiceName={invoice?.sellerName}
          actionText="Delete"
          onCancel={() => {
            setDeleteConfirmation(false);
          }}
          onConfirm={() => {
            deleteInvoiceId(businessId, invoice?._id);
          }}
          cancelText="Cancel"
          cancelClassName="w-[200px] border border-[#000000] rounded bg-transparent text-[#061A40] px-5 py-2.5 text-sm font-normal"
          actionClassName="w-full min-w-fit px-3 py-3 text-base bg-[#061A40] rounded font-normal text-center text-white"
          isSubmitting={isSubmitting}
        />
      )}

      {confirmationModalActive && (
        <EnhancedConfirmModal
          title="Make a payment"
          content={`Payment would be made to `}
          invoiceName={invoice?.sellerName}
          actionText="Proceed with Payment"
          onCancel={() => {
            setConfirmationModalActive(false);
            setProceedWithPayment(false);
            setIsSubmitting(false);
          }}
          cancelText="Cancel"
          cancelClassName="w-[200px] border border-[#000000] rounded bg-transparent text-[#061A40] px-5 py-2.5 text-sm font-normal"
          actionClassName="w-full min-w-fit px-3 py-3 text-base bg-[#061A40] rounded font-normal text-center text-white"
          onPaymentClose={() => {
            toast.error('Payment closed');
          }}
          businessId={businessId}
          invoiceId={invoice?._id}
          email={email}
          amount={invoice?.amount}
          currency={invoice?.currency}
          continuePayment={proceedWithPayment}
          onConfirm={() => {
            setProceedWithPayment(true);
          }}
          onBack={() => {
            setProceedWithPayment(false);
            setIsSubmitting(false);
          }}
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
          setConfirmationModalActive={setConfirmationModalActive}
          setProceedWithPayment={setProceedWithPayment}
          setRunAfterPayment={setRunAfterPayment}
        />
      )}
      {markInvoiceModal && (
        <EnhancedReceiptModal
          title="Proof of Payment"
          invoiceName={invoice?.sellerName}
          actionText="Confirm"
          onCancel={() => {
            setMarkInvoiceModal(false);
          }}
          onConfirm={() => {
            formik.handleSubmit();
          }}
          cancelText="Cancel"
          cancelClassName="w-full border border-[#000000] rounded bg-transparent px-5 py-2.5 text-sm font-normal"
          actionClassName="w-full px-3 py-3 text-base bg-[#061A40] rounded font-normal text-white"
          onPaymentClose={() => {
            toast.error('Payment close');
          }}
          businessId={businessId}
          formik={formik}
          invoice={invoice}
          isSubmitting={formik.isSubmitting}
        />
      )}
      {markInvoiceForSeller && (
        <EnhancedReceiptModal
          title="Proof of Payment"
          invoiceName={invoice?.sellerName}
          actionText="Confirm"
          onCancel={() => {
            setMarkInvoiceFromSeller(false);
          }}
          onConfirm={() => {
            handleMarkAsPaidForSeller();
          }}
          cancelText="Decline"
          cancelClassName="w-full border border-black rounded bg-declineBg text-white px-5 py-2.5 text-sm font-normal"
          actionClassName="w-full px-3 py-3 text-base bg-demoBtnBg rounded font-normal text-white"
          onPaymentClose={() => {
            toast.error('Payment close');
          }}
          businessId={businessId}
          formik={formik}
          invoice={invoice}
          isSubmitting={isLoading}
          markFromSeller={true}
        />
      )}
    </div>
  );
};

export default Action;
