import { useState } from 'react';
import ModalPortal from '../../ModalPortal';
import { Icon, Icons } from '../../Icon';
import Button from '../../Form/Button';

type Props = {
  onCancel: () => void;
  onAddDirectly: () => void;
  onAddOneByOne: () => void;
  onAddBulk: () => void;
};

type Option = 'direct' | 'one' | 'bulk' | '';

export default function AddVendorMenu({
  onCancel,
  onAddOneByOne,
  onAddBulk,
  onAddDirectly,
}: Props) {
  const [selectedOption, setSelectedOption] = useState<Option>('');

  const handleSubmit = () => {
    if (selectedOption === 'one') {
      onAddOneByOne();
      return;
    }
    if (selectedOption === 'bulk') {
      onAddBulk();
      return;
    }
    if (selectedOption === 'direct') {
      onAddDirectly();
      return;
    }
  };

  return (
    <ModalPortal>
      <div className="fixed top-0 z-50 flex h-screen overflow-auto w-screen items-center justify-center bg-[#000000] bg-opacity-60">
        <div className="w-1/2 min-w-[800px] max-[850px]:w-[95%] max-[750px]:min-w-[95%] max-[500px]:w-[90%] max-[600px]:relative px-8">
          <div className="flex flex-col items-end justify-end max-[600px]:absolute top-[-40px] right-[-10px]">
            <div role="button" tabIndex={0} className="cursor-pointer" onClick={onCancel}>
              <Icon name={Icons.Close} />
            </div>
          </div>
          <div className="mr-12 max-[600px]:mr-0 p-10 max-[600px]:p-4 max-[600px]:h-[500px] max-[600px]:w-full overflow-y-auto bg-white rounded-[20px]">
            <div className="flex flex-col items-center justify-center">
              <div className="text-center">
                <p className="text-xl font-semibold font-inter">Add Vendor</p>
                <span className="text-base font-normal text-black/40 font-inter">
                  Select mode of adding vendor
                </span>
              </div>

              <div className="flex max-[750px]:flex-wrap max-[750px]:items-center max-[750px]:justify-center gap-6 mt-10">
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => setSelectedOption('direct')}
                  className={`w-full p-2 text-center min-[720px]:max-w-[14rem] max-[720px]:max-w-[10rem] max-[600px]:w-[100%] cursor-pointer border ${
                    selectedOption === 'direct'
                      ? 'border-[#0353A4] bg-[#B9D6F238]'
                      : 'bg-white border-black/20'
                  } rounded-lg shadow`}
                >
                  {selectedOption === 'direct' ? (
                    <div className="flex justify-end">
                      <Icon name={Icons.CheckMark} />
                    </div>
                  ) : (
                    <div className="h-[18px]" />
                  )}
                  <div className="flex flex-col items-center justify-center p-4">
                    <Icon name={Icons.SingleFile} />
                    <div className="p-4">
                      <h5 className="text-sm font-medium text-black font-inter">
                        Add vendor directly
                      </h5>
                      <h5 className="mt-3 text-[10px] font-normal text-black/40 font-inter">
                        vendor will not need to register
                      </h5>
                    </div>
                  </div>
                </div>

                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => setSelectedOption('one')}
                  className={`w-full p-2 text-center min-[720px]:max-w-[14rem] max-[720px]:max-w-[10rem] max-[600px]:w-[100%] cursor-pointer border ${
                    selectedOption === 'one'
                      ? 'border-[#0353A4] bg-[#B9D6F238]'
                      : 'bg-white border-black/20'
                  } rounded-lg shadow`}
                >
                  {selectedOption === 'one' ? (
                    <div className="flex justify-end">
                      <Icon name={Icons.CheckMark} />
                    </div>
                  ) : (
                    <div className="h-[18px]" />
                  )}
                  <div className="flex flex-col items-center justify-center p-4">
                    <Icon name={Icons.SingleFile} />
                    <div className="p-4">
                      <h5 className="text-sm font-medium text-black font-inter">
                        Add one by one
                      </h5>
                      <h5 className="mt-3 text-[10px] font-normal text-black/40 font-inter">
                        Add vendor one at a time
                      </h5>
                    </div>
                  </div>
                </div>
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => setSelectedOption('bulk')}
                  className={`w-full p-2 text-center min-[720px]:max-w-[14rem] max-[720px]:max-w-[10rem] cursor-pointer border ${
                    selectedOption === 'bulk'
                      ? 'border-[#0353A4] bg-[#B9D6F238]'
                      : 'bg-white border-black/20'
                  } rounded-lg shadow`}
                >
                  {selectedOption === 'bulk' ? (
                    <div className="flex justify-end">
                      <Icon name={Icons.CheckMark} />
                    </div>
                  ) : (
                    <div className="h-[18px]" />
                  )}
                  <div className="flex flex-col items-center justify-center p-4">
                    <Icon name={Icons.MultipleFile} />
                    <div className="p-4">
                      <h5 className="text-sm font-medium text-black font-inter">
                        Bulk upload in csv
                      </h5>
                      <h5 className="mt-3 text-[10px] font-normal text-black/40 font-inter">
                        Upload multiple vendor
                      </h5>
                    </div>
                  </div>
                </div>
              </div>

              <Button
                type="button"
                variant="primary"
                size="md"
                label="Continue"
                className="mt-10"
                disabled={!selectedOption}
                onClick={handleSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </ModalPortal>
  );
}
